import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import * as Yup from "yup";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { Form, FormikProvider, useFormik } from "formik";
// material
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@emotion/react";
import {
  Card,
  Grid,
  Stack,
  Select,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
  FormControlLabel,
  FormGroup,
  Skeleton,
  Switch,
  MenuItem,
  InputLabel,
  Autocomplete,
  Divider,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import imageCompression from "browser-image-compression";

import * as api from "src/services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import configData from "src/configData";
import { useTranslation } from "react-i18next";

import { UploadMultiFile, UploadSingleFile } from "src/components/upload";
// ----------------------------------------------------------------------
const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
  span: {
    fontSize: 12,
    float: "right",
    fontWeight: 400,
  },
}));

// ----------------------------------------------------------------------

export default function ProductForm({
  categories,
  currentProduct,
  categoryLoading,
  isInitialized,
}) {
  const [mapDeleteIndex, setMapDeleteIndex] = useState(null);
  const queryClient = useQueryClient();
  const mode = useSelector((state) => state.settings.mode);
  const [edit, setEdit] = useState(true);
  const [imageLoading, setImageLoading] = useState(false);
  const [MultiImageLoading, setMultiImageLoading] = useState(false);
  const [multiEdit, setMultiEdit] = useState(true);
  const [multiEditDoc, setMultiEditDoc] = useState(true);
  const { t } = useTranslation("product");
  const { pid } = useParams();
  const navigate = useNavigate();
  const { data: locationData, isLoading: locationLoading } = useQuery(
    ["locationData"],
    () => api.getTrueLocation("")
  );
  const { data: productList, isLoading: productListLoading } = useQuery(
    ["TrueProductList"],
    api.getAllTrueProductList
  );
  const locationsList = locationData?.data;
  // console.log(locationsList);
  const { data: addonsData, isLoading: addonLoading } = useQuery(
    ["addonsData"],
    () => api.getAddOns("")
  );
  const addOns = addonsData?.data;

  const { data: zones, isLoading: zoneLoading } = useQuery(
    ["zoneList"],
    api.getALLTrueZone
  );
  const zoneList = zones?.data;

  const { mutate: deleteGallery, isLoading: deleteLoading } = useMutation(
    api.singleDeleteFile,
    {
      onSuccess: () => {
        setMapDeleteIndex(null);
        queryClient.invalidateQueries([`get-product-details${pid}`]);
        toast.success(t(`common:errors.deleted-success`));
      },
    }
  );
  const { mutate, isLoading: updateLoading } = useMutation(
    pid ? api.updateProduct : api.newProduct,
    {
      onSuccess: () => {
        navigate("/products");

        toast.success(
          pid
            ? t(`common:errors.product-updated`)
            : t(`common:errors.product-created`)
        );
      },
      onError: ({ response }) => {
        if (response.status === 409) {
          toast.error(`${response.data.message}`);
        }
      },
    }
  );
  const NewProductSchema = Yup.object().shape({
    name: Yup.string().required(t("name-is-required")),
    description: Yup.string().required(t("description-is-required")),
    category: Yup.string().required(t("category-is-required")),
    house_width: Yup.string().required(t("house-width-is-required")),
    lot_width: Yup.string().required(t("lot-width-is-required")),
    meta_description: Yup.string().required(t("meta-description-is-required")),
    meta_title: Yup.string().required(t("meta-title-is-required")),
    meta_tags: Yup.array().required(t("meta-tags-are-required")),
    slug: Yup.string().required(t("slug-is-required")),
    // unit: Yup.string().required(t("unit-is-required")),
    size: Yup.string().required(t("size-is-required")),
    location: Yup.array().required(t("city-is-required")),
    stories: Yup.number().required(t("No. of storeys is required")),
    price: Yup.number().required(t("price-is-required")),
    image: Yup.mixed().required(t("image-is-required")),
    maps: Yup.array(),
    // add_ons: Yup.array().required(t("Add Ons-is-required")),
    layout: Yup.mixed(),
  });

  const currentAddOns =
    !addonLoading &&
    addOns?.filter(
      (item) => currentProduct && currentProduct?.add_ons.includes(item?._id)
    );

  const currentZone =
    !zoneLoading &&
    zoneList?.filter(
      (item) => currentProduct && currentProduct?.zone.includes(item?._id)
    );

  const currentSimilarProductArray =
    !productListLoading &&
    productList?.filter(
      (item) =>
        currentProduct && currentProduct?.similar_products?.includes(item?._id)
    );

  const currentLocation =
    !locationLoading &&
    locationsList?.filter(
      (item) => currentProduct && currentProduct?.location.includes(item?._id)
    );
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: currentProduct?.name || "",
      description: currentProduct?.description || "",
      category:
        currentProduct?.category || (categories && categories[0]?._id) || "",
      house_width: currentProduct?.house_width || "",
      // currentProduct?.unit === "feet"
      //   ? currentProduct?.house_width / 12
      //   : currentProduct?.house_width || "",
      lot_width: currentProduct?.lot_width || "",
      // currentProduct?.unit === "feet"
      //   ? currentProduct?.lot_width / 12
      //   : currentProduct?.lot_width || "",
      size: currentProduct?.size || "",
      // currentProduct?.unit === "feet"
      //   ? currentProduct?.size / 12
      //   : currentProduct?.size || "",
      similar_products: currentSimilarProductArray || [],
      location: currentLocation || [],
      add_ons: currentAddOns || [],
      // unit: currentProduct?.unit || "feet",
      is_featured: currentProduct ? currentProduct?.is_featured : false,
      coming_soon: currentProduct ? currentProduct?.coming_soon : false,
      price: currentProduct?.price === 0 ? "0" : currentProduct?.price || "",
      image: currentProduct?.image || null,
      layout: currentProduct?.layout || "Landscape",
      stories: currentProduct?.stories || "1",
      gallery: currentProduct?.gallery || [],
      blob: currentProduct?.blob || [],
      zone: currentZone || [],
      meta_description: currentProduct?.meta_description || "",
      meta_title: currentProduct?.meta_title || "",
      meta_tags: currentProduct?.meta_tag || [],
      slug: currentProduct?.slug || "",
      maps: currentProduct?.maps || [],
    },

    validationSchema: NewProductSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("category", values.category);
      formData.append("meta_description", values.meta_description);
      formData.append("meta_title", values.meta_title);
      formData.append("slug", values.slug);
      formData.append("description", values.description);
      formData.append("house_width", values.house_width);
      formData.append("image", values.image);
      formData.append("is_featured", values.is_featured);
      formData.append("coming_soon", values.coming_soon);
      formData.append("lot_width", values.lot_width);
      formData.append("layout", values.layout);

      //map
      values?.maps.length > 0
        ? values?.maps.map((item) => {
            formData.append("maps", item);
          })
        : formData.append("maps", "[]");

      //Add Ons
      values.add_ons.length > 0
        ? values?.add_ons?.map((item) => {
            formData.append("add_ons", [item?._id]);
          })
        : formData.append("add_ons", "[]");
      //Similar Products
      values?.similar_products?.length > 0
        ? values?.similar_products?.map((item) => {
            formData.append("similar_products", [item?._id]);
          })
        : formData.append("similar_products", "[]");

      //Zone
      values.zone.length > 0
        ? values?.zone?.map((item) => {
            formData.append("zone", [item?._id]);
          })
        : formData.append("zone", "[]");

      //Location
      values.location.length > 0
        ? values?.location?.map((item) => {
            formData.append("location", [item?._id]);
          })
        : formData.append("location", "[]");

      //Gallery
      values.gallery.length > 0
        ? values?.gallery?.map((item) => {
            formData.append("gallery", item);
          })
        : formData.append("gallery", "[]");
      //meta tags
      values.meta_tags.length > 0
        ? values.meta_tags.map((item) => {
            formData.append("meta_tag", item);
          })
        : formData.append("meta_tag", "[]");

      formData.append("price", values.price);
      formData.append("stories", values.stories);
      formData.append("size", values.size);
      formData.forEach((v, k) => console.log("FormData", k, v));
      try {
        mutate({ formData, ...(currentProduct && { id: currentProduct._id }) });
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    setFieldValue,
    getFieldProps,
  } = formik;

  const handleDrop = async (acceptedFiles) => {
    if (acceptedFiles[0]) {
      setEdit(false);
      setImageLoading(true);
      const compressedFile = await imageCompression(acceptedFiles[0], {
        maxSizeMB: 0.1,
      });
      const myFile = new File([compressedFile], compressedFile.name, {
        type: compressedFile.type,
      });
      console.log(acceptedFiles, "BLOB");
      if (myFile) {
        Object.assign(myFile, {
          preview: URL.createObjectURL(myFile),
        });
        setImageLoading(false);
        setFieldValue("image", myFile);
      }
    }
  };
  const handleMultiDrop = async (acceptedFiles) => {
    if (acceptedFiles[0]) {
      setMultiImageLoading(true);
      setMultiEdit(false);
      let filesArr = [];
      acceptedFiles?.map(async (item) => {
        const compressedFile = await imageCompression(item, {
          maxSizeMB: 0.1,
        });
        const myFile = new File([compressedFile], compressedFile.name, {
          type: compressedFile.type,
        });
        filesArr.push(myFile);
        if (myFile) {
          setMultiImageLoading(false);
        }
      });
      setFieldValue("gallery", filesArr);
      const blobs = acceptedFiles.map((file) => {
        return URL.createObjectURL(file);
      });

      setFieldValue("blob", blobs);
    }
  };

  const handleMultiMapDrop = async (acceptedFiles) => {
    if (acceptedFiles[0]) {
      setMultiEditDoc(false);
      let filesArr = [];
      acceptedFiles?.map(async (item) => {
        filesArr.push(item);
      });
      setFieldValue("maps", filesArr);
    }
  };

  const handleRemoveAll = (key) => {
    if (key === "map") {
      setFieldValue("maps", []);
    } else {
      setFieldValue("gallery", []);
      setFieldValue("blob", []);
    }
  };
  function handleRemove(file, index) {
    if (!multiEditDoc) {
      setFieldValue(
        "blob",
        values.blob?.filter((item) => item !== file)
      );
    } else {
      setMapDeleteIndex(index);

      deleteGallery({ id: pid, type: "gallery", url: file });
      setFieldValue(
        "blob",
        values.blob?.filter((item) => item !== file)
      );
    }
  }
  function handleMapRemove(file, index) {
    if (!multiEditDoc) {
      setFieldValue(
        "maps",
        values.maps.filter((_, ind) => ind !== index)
      );
    } else {
      setMapDeleteIndex(index);
      deleteGallery({ id: pid, type: "map", url: file });
    }
  }

  const layout = ["Landscape", "Portrait"];

  const theme = useTheme();
  console.log(values, "values");
  return (
    <Stack spacing={3}>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <Stack spacing={3}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Stack
                      direction={"row"}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h4"
                        color={mode === "dark" ? "white" : "black"}
                      >
                        {pid ? t("edit-products") : t("add-products")}
                      </Typography>
                      {pid && (
                        <Typography
                          variant="h6"
                          color={mode === "dark" ? "white" : "black"}
                        >
                          Product No : {currentProduct?.serial_number}
                        </Typography>
                      )}
                    </Stack>

                    <div>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          {isInitialized ? (
                            <Skeleton variant="text" width={140} />
                          ) : (
                            <LabelStyle>{t("product-name")}</LabelStyle>
                          )}
                          {isInitialized ? (
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height={56}
                            />
                          ) : (
                            <TextField
                              fullWidth
                              {...getFieldProps("name")}
                              error={Boolean(touched.name && errors.name)}
                              helperText={touched.name && errors.name}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          {isInitialized ? (
                            <Skeleton variant="text" width={140} />
                          ) : (
                            <LabelStyle>{t("slug")}</LabelStyle>
                          )}
                          {isInitialized ? (
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height={56}
                            />
                          ) : (
                            <TextField
                              fullWidth
                              {...getFieldProps("slug")}
                              error={Boolean(touched.slug && errors.slug)}
                              helperText={touched.slug && errors.slug}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </div>

                    <div>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                          {isInitialized ? (
                            <Skeleton variant="text" width={140} />
                          ) : (
                            <LabelStyle>{t("house_width")}</LabelStyle>
                          )}
                          {isInitialized ? (
                            <Skeleton
                              variant="rectangular"
                              width="33%"
                              height={56}
                            />
                          ) : (
                            <TextField
                              {...getFieldProps("house_width")}
                              error={Boolean(
                                touched.house_width && errors.house_width
                              )}
                              helperText={
                                touched.house_width && errors.house_width
                              }
                              placeholder="in feet"
                              type="number"
                              size="medium"
                              fullWidth
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} md={3}>
                          {isInitialized ? (
                            <Skeleton variant="text" width={140} />
                          ) : (
                            <LabelStyle>{t("lot_width")}</LabelStyle>
                          )}
                          {isInitialized ? (
                            <Skeleton
                              variant="rectangular"
                              width="33%"
                              height={56}
                            />
                          ) : (
                            <TextField
                              {...getFieldProps("lot_width")}
                              error={Boolean(
                                touched.lot_width && errors.lot_width
                              )}
                              type="number"
                              placeholder="in feet"
                              helperText={touched.lot_width && errors.lot_width}
                              size="medium"
                              fullWidth
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} md={3}>
                          {isInitialized ? (
                            <Skeleton variant="text" width={140} />
                          ) : (
                            <LabelStyle>{t("size")}</LabelStyle>
                          )}
                          {isInitialized ? (
                            <Skeleton
                              variant="rectangular"
                              width="33%"
                              height={56}
                            />
                          ) : (
                            <TextField
                              {...getFieldProps("size")}
                              type="number"
                              error={Boolean(touched.size && errors.size)}
                              helperText={touched.size && errors.size}
                              placeholder="in feet"
                              size="medium"
                              fullWidth
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} md={3}>
                          {isInitialized ? (
                            <Skeleton variant="text" width={140} />
                          ) : (
                            <LabelStyle>{t("NoofStoreys")}</LabelStyle>
                          )}
                          {isInitialized ? (
                            <Skeleton
                              variant="rectangular"
                              width="33%"
                              height={56}
                            />
                          ) : (
                            <Select fullWidth {...getFieldProps("stories")}>
                              <MenuItem value={"1"}>1</MenuItem>
                              <MenuItem value={"2"}>2</MenuItem>
                              <MenuItem value={"3"}>3</MenuItem>
                              <MenuItem value={"4"}>4</MenuItem>
                              <MenuItem value={"5"}>5</MenuItem>
                              <MenuItem value={"6"}>6</MenuItem>
                            </Select>
                          )}
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <FormControl
                            fullWidth
                            error={Boolean(touched.location && errors.location)}
                          >
                            {isInitialized ? (
                              <Skeleton variant="text" width={140} />
                            ) : (
                              <LabelStyle>{t("City")}</LabelStyle>
                            )}
                            {isInitialized ? (
                              <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={56}
                              />
                            ) : (
                              <>
                                <Autocomplete
                                  sx={{ textTransform: "capitalize" }}
                                  multiple={true}
                                  {...getFieldProps("location")}
                                  size="medium"
                                  onChange={(_, val) =>
                                    setFieldValue("location", val)
                                  }
                                  filterSelectedOptions
                                  getOptionLabel={(location) => (
                                    <Typography color={theme.palette.text.auto}>
                                      {location.name}
                                    </Typography>
                                  )}
                                  isOptionEqualToValue={(option, value) =>
                                    option._id === value._id
                                  }
                                  options={locationsList ? locationsList : [""]}
                                  noOptionsText={"NO DATA FOUND"}
                                  renderOption={(props, location) => (
                                    <MenuItem
                                      {...props}
                                      sx={{ textTransform: "capitalize" }}
                                    >
                                      {location.name}
                                    </MenuItem>
                                  )}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  error={Boolean(
                                    touched.location && errors.location
                                  )}
                                  helperText={
                                    touched.location && errors.location
                                  }
                                />
                              </>
                            )}
                            {touched.location && errors.location && (
                              <FormHelperText error sx={{ px: 2, mx: 0 }}>
                                {touched.location && errors.location}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <FormControl
                            fullWidth
                            error={Boolean(touched.category && errors.category)}
                          >
                            {isInitialized ? (
                              <Skeleton variant="text" width={100} />
                            ) : (
                              <LabelStyle>{t("category")}</LabelStyle>
                            )}
                            {!categoryLoading ? (
                              <Select
                                {...getFieldProps("category")}
                                value={values.category}
                                id="grouped-native-select"
                              >
                                {categories?.map((item) => (
                                  <MenuItem value={item._id} key={item._id}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            ) : (
                              <Skeleton
                                variant="rectangular"
                                width={"100%"}
                                height={56}
                              />
                            )}
                            {touched.category && errors.category && (
                              <FormHelperText error sx={{ px: 2, mx: 0 }}>
                                {touched.category && errors.category}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          {isInitialized ? (
                            <Skeleton variant="text" width={140} />
                          ) : (
                            <LabelStyle>{t("Add Ons")}</LabelStyle>
                          )}
                          {isInitialized ? (
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height={56}
                            />
                          ) : (
                            <Autocomplete
                              sx={{ textTransform: "capitalize" }}
                              multiple={true}
                              {...getFieldProps("add_ons")}
                              size="medium"
                              onChange={(_, val) =>
                                setFieldValue("add_ons", val)
                              }
                              filterSelectedOptions
                              getOptionLabel={(addOns) => (
                                <Typography color={theme.palette.text.auto}>
                                  {addOns.name}
                                </Typography>
                              )}
                              isOptionEqualToValue={(option, value) =>
                                option._id === value._id
                              }
                              options={addOns ? addOns : [""]}
                              noOptionsText={"NO DATA FOUND"}
                              renderOption={(props, addOns) => (
                                <MenuItem
                                  {...props}
                                  sx={{ textTransform: "capitalize" }}
                                >
                                  {addOns.name}
                                </MenuItem>
                              )}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          )}
                          {touched.add_ons && errors.add_ons && (
                            <FormHelperText error sx={{ px: 2, mx: 0 }}>
                              {touched.add_ons && errors.add_ons}
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={12} md={12}>
                          {isInitialized ? (
                            <Skeleton variant="text" width={140} />
                          ) : (
                            <LabelStyle>{t("Zone")}</LabelStyle>
                          )}
                          {isInitialized ? (
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height={56}
                            />
                          ) : (
                            <Autocomplete
                              sx={{ textTransform: "capitalize" }}
                              multiple={true}
                              {...getFieldProps("zone")}
                              size="medium"
                              onChange={(_, val) => setFieldValue("zone", val)}
                              filterSelectedOptions
                              getOptionLabel={(zone) => (
                                <Typography color={theme.palette.text.auto}>
                                  {zone.name}
                                </Typography>
                              )}
                              isOptionEqualToValue={(option, value) =>
                                option._id === value._id
                              }
                              options={zoneList ? zoneList : [""]}
                              noOptionsText={"NO DATA FOUND"}
                              renderOption={(props, zone) => (
                                <MenuItem
                                  {...props}
                                  sx={{ textTransform: "capitalize" }}
                                >
                                  {zone.name}
                                </MenuItem>
                              )}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          )}
                          {touched.zone && errors.zone && (
                            <FormHelperText error sx={{ px: 2, mx: 0 }}>
                              {touched.zone && errors.zone}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                    <div>
                      {isInitialized ? (
                        <Skeleton variant="text" width={120} />
                      ) : (
                        <LabelStyle> {t("description")} </LabelStyle>
                      )}
                      {isInitialized ? (
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={240}
                        />
                      ) : (
                        <TextField
                          error={Boolean(
                            touched.description && errors.description
                          )}
                          helperText={touched.description && errors.description}
                          fullWidth
                          {...getFieldProps("description")}
                          rows={9}
                          multiline
                        />
                      )}
                    </div>
                    <Grid item xs={12} md={12}>
                      {isInitialized ? (
                        <Skeleton variant="text" width={140} />
                      ) : (
                        <LabelStyle>{t("Similar Products")}</LabelStyle>
                      )}
                      {isInitialized ? (
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={56}
                        />
                      ) : (
                        <Autocomplete
                          sx={{ textTransform: "capitalize" }}
                          multiple={true}
                          {...getFieldProps("similar_products")}
                          size="medium"
                          onChange={(_, val) =>
                            setFieldValue("similar_products", val)
                          }
                          filterSelectedOptions
                          getOptionLabel={(product) => (
                            <Typography color={theme.palette.text.auto}>
                              {product.name}
                            </Typography>
                          )}
                          isOptionEqualToValue={(option, value) =>
                            option._id === value._id
                          }
                          options={productList ? productList : [""]}
                          noOptionsText={"NO DATA FOUND"}
                          renderOption={(props, product) => (
                            <MenuItem
                              {...props}
                              sx={{ textTransform: "capitalize" }}
                            >
                              {product.name}
                            </MenuItem>
                          )}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Accordion
                        sx={{ border: "1px solid #ddd !important" }}
                        defaultExpanded
                      >
                        <AccordionSummary
                          expandIcon={<KeyboardArrowDownIcon />}
                        >
                          <Typography variant="h6" textAlign={"center"}>
                            META DATA
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            {isInitialized ? (
                              <Skeleton variant="text" width={120} />
                            ) : (
                              <LabelStyle> {t("meta-description")} </LabelStyle>
                            )}
                            {isInitialized ? (
                              <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={240}
                              />
                            ) : (
                              <TextField
                                error={Boolean(
                                  touched.meta_description &&
                                    errors.meta_description
                                )}
                                helperText={
                                  touched.meta_description &&
                                  errors.meta_description
                                }
                                fullWidth
                                {...getFieldProps("meta_description")}
                                rows={9}
                                multiline
                              />
                            )}
                          </div>
                          <Grid item xs={12}>
                            {isInitialized ? (
                              <Skeleton variant="text" width={120} />
                            ) : (
                              <LabelStyle> {t("meta-title")} </LabelStyle>
                            )}
                            {isInitialized ? (
                              <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={240}
                              />
                            ) : (
                              <TextField
                                error={Boolean(
                                  touched.meta_title && errors.meta_title
                                )}
                                helperText={
                                  touched.meta_title && errors.meta_title
                                }
                                fullWidth
                                {...getFieldProps("meta_title")}
                              />
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            {isInitialized ? (
                              <Skeleton variant="text" width={120} />
                            ) : (
                              <LabelStyle> {t("meta-tags")} </LabelStyle>
                            )}
                            {isInitialized ? (
                              <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={240}
                              />
                            ) : (
                              <Autocomplete
                                multiple
                                // {...getFieldProps("meta_tags")}
                                options={
                                  values.meta_tags.map((item) =>
                                    item.toString()
                                  ) ?? []
                                }
                                value={
                                  values.meta_tags.map((item) =>
                                    item.toString()
                                  ) ?? []
                                }
                                freeSolo
                                onChange={(_, val) =>
                                  setFieldValue("meta_tags", val)
                                }
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip
                                      variant="outlined"
                                      label={option}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            )}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Stack>
                </Card>
              </Stack>
            </Grid>
            <Grid item xs={12} md={5}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3} pb={1}>
                  <Grid contianer spacing={2}>
                    <Grid item xs={12}>
                      {isInitialized ? (
                        <Skeleton variant="text" width={70} />
                      ) : (
                        <LabelStyle> {t("price")}</LabelStyle>
                      )}
                      {isInitialized ? (
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={56}
                        />
                      ) : (
                        <TextField
                          error={Boolean(touched.price && errors.price)}
                          helperText={touched.price && errors.price}
                          fullWidth
                          type="number"
                          values={values}
                          setFieldValue={setFieldValue}
                          {...getFieldProps("price")}
                          fieldName="price"
                        />
                      )}
                    </Grid>
                  </Grid>
                  <div>
                    {isInitialized ? (
                      <Skeleton variant="text" width={150} />
                    ) : (
                      <LabelStyle>{t("image")}</LabelStyle>
                    )}
                    {isInitialized ? (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={225}
                      />
                    ) : (
                      <UploadSingleFile
                        file={values.image}
                        accept={{
                          "image/png": [
                            ".png",
                            ".jpg",
                            ".jpeg",
                            ".webp",
                            ".svg",
                          ],
                        }}
                        isEdit={edit}
                        setImageLoading={setImageLoading}
                        onDrop={handleDrop}
                        error={Boolean(touched.image && errors.image)}
                        category
                        loading={imageLoading}
                      />
                    )}
                    {touched.cover && errors.cover && (
                      <FormHelperText error sx={{ px: 2, mx: 0 }}>
                        {touched.cover && errors.cover}
                      </FormHelperText>
                    )}
                  </div>
                  <Grid item xs={12} md={12}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.layout && errors.layout)}
                    >
                      {isInitialized ? (
                        <Skeleton variant="text" width={100} />
                      ) : (
                        <LabelStyle>{t("Layout")}</LabelStyle>
                      )}
                      {!categoryLoading ? (
                        <Select
                          onChange={(e) =>
                            setFieldValue("layout", e.target.value)
                          }
                          {...getFieldProps("layout")}
                          value={values.layout}
                          id="grouped-native-select"
                        >
                          {layout?.map((item, index) => (
                            <MenuItem value={item} key={index}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <Skeleton
                          variant="rectangular"
                          width={"100%"}
                          height={56}
                        />
                      )}
                      {touched.layout && errors.layout && (
                        <FormHelperText error sx={{ px: 2, mx: 0 }}>
                          {touched.layout && errors.layout}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <div>
                    {isInitialized ? (
                      <Skeleton variant="text" width={150} />
                    ) : (
                      <LabelStyle>{t("products-images")}</LabelStyle>
                    )}
                    {isInitialized ? (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={225}
                      />
                    ) : (
                      <>
                        <UploadMultiFile
                          showPreview
                          isEdit={multiEdit}
                          accept={{
                            "image/png": [
                              ".png",
                              ".jpg",
                              ".jpeg",
                              ".webp",
                              ".svg",
                            ],
                          }}
                          files={multiEdit ? values.gallery : values.blob}
                          loading={false}
                          imageLoading={MultiImageLoading}
                          onDrop={handleMultiDrop}
                          deleteIndex={mapDeleteIndex}
                          onRemove={handleRemove}
                          onRemoveAll={() => handleRemoveAll("gallery")}
                          blob={values.blob}
                          error={Boolean(touched.gallery && errors.gallery)}
                        />
                        {touched.gallery && errors.gallery && (
                          <FormHelperText error sx={{ px: 2 }}>
                            {touched.gallery && errors.gallery}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  </div>

                  <div>
                    {isInitialized ? (
                      <Skeleton variant="text" width={150} />
                    ) : (
                      <LabelStyle>{t("Plans-fullset")}</LabelStyle>
                    )}
                    {isInitialized ? (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={225}
                      />
                    ) : (
                      <>
                        <UploadMultiFile
                          showPreview
                          isEdit={multiEdit}
                          accept={{
                            "image/pdf": [".pdf", ".dxf", ".dwg"],
                          }}
                          files={values.maps}
                          loading={false}
                          type={"map"}
                          deleteIndex={mapDeleteIndex}
                          deleteLoading={deleteLoading}
                          onDrop={handleMultiMapDrop}
                          onRemove={handleMapRemove}
                          onRemoveAll={() => handleRemoveAll("map")}
                          error={Boolean(touched.maps && errors.maps)}
                        />
                        {touched.maps && errors.maps && (
                          <FormHelperText error sx={{ px: 2 }}>
                            {touched.maps && errors.maps}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  </div>
                  {!values.coming_soon && (
                    <div>
                      {isInitialized ? (
                        <Skeleton variant="text" width={20} />
                      ) : (
                        <LabelStyle>{t("featured-product")}</LabelStyle>
                      )}
                      {isInitialized ? (
                        <Skeleton
                          variant="rectangular"
                          width="20%"
                          height={20}
                        />
                      ) : (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values?.is_featured}
                                setFieldValue={setFieldValue}
                                {...getFieldProps("is_featured")}
                              />
                            }
                            label={t("featured-product")}
                          />
                        </FormGroup>
                      )}
                    </div>
                  )}
                  {!values.is_featured && (
                    <div>
                      {isInitialized ? (
                        <Skeleton variant="text" width={20} />
                      ) : (
                        <LabelStyle>{t("coming-soon")}</LabelStyle>
                      )}
                      {isInitialized ? (
                        <Skeleton
                          variant="rectangular"
                          width="20%"
                          height={20}
                        />
                      ) : (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values?.coming_soon}
                                setFieldValue={setFieldValue}
                                {...getFieldProps("coming_soon")}
                              />
                            }
                            label={t("coming-soon")}
                          />
                        </FormGroup>
                      )}
                    </div>
                  )}
                  <div></div>
                  <Stack spacing={2}>
                    {isInitialized ? (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={56}
                      />
                    ) : (
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        size="large"
                        fullWidth
                        loading={updateLoading}
                      >
                        {pid ? t("update-product") : t("create-product")}
                      </LoadingButton>
                    )}
                  </Stack>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Stack>
  );
}
