export const ApiUrl = {
  IMAGE_BASE_URL: "https://storage.googleapis.com/cityplans/",
  LOGIN_URL: "auth/login",
  REGISTER_URL: "/admin/register",
  ADD_CATEGORY_URL: `category/add`,
  GET_CATEGORY_URL: `category/get?is_active=true`,
  GET_CATEGORY_PROFILE: `category/profile/`,
  GET_ALL_CATEGORY_URL: `/category/get`,
  EDIT_CATEGORY_URL: `category/edit`,
  ADD_PRODUCT_URL: "product/add",
  GET_PRODUCT_URL: "product/get",
  GET_PRODUCT_TRUE_LIST_URL: "product/get?is_active=true&search=",
  GET_PRODUCT_PROFILE_URL: "product/profile/",
  UPDATE_PRODUCT_STATUS_URL: "product/status-change/",
  UPDATE_PRODUCT_URL: "product/edit/",
  ROLE_LIST_URL: "role/get",
  ALL_ROLE_LIST_URL: "role/get-all",
  ROLE_OPTION_URL: "roles-options/get",
  ADD_ROLE_OPTION_URL: "roles-options/add",
  UPDATE_ROLE_OPTION_URL: "roles-options/edit/",
  ADD_LOCATION_URL: "locations/add",
  GET_ALL_LOCATION_URL: "locations/get",
  GET_ALL_TRUE_LOCATION_URL: "locations/get?is_active=true",
  UPDATE_LOCATION_URL: "locations/edit/",
  ADD_ROLE_URL: "role/add",
  UPDATE_ROLE_URL: "role/edit/",
  ROLE_PROFILE_URL: "role/profile/",
  USER_PROFILE_URL: "user/profile/",
  ADD_BANNER_URL: "banner/add",
  UPDATE_BANNER_URL: "banner/edit/",
  GET_BANNERS_URL: "banner/get",
  GET_USER_LIST_URL: "user/get",
  ADD_USER_URL: "user/add",
  UPDATE_USER_URL: "user/edit/",
  GET_ADD_ONS_URL: "add-ons/get",
  ADD_ADD_ONS_URL: "add-ons/add",
  UPDATE_ADD_ONS_URL: "add-ons/edit/",
  GET_ORDERS_URL: "orders/get",
  GET_ORDER_PROFILE_URL: "orders/profile/",
  GET_DOCUMENT_CATEGORY: "document-category/get",
  GET_ACTIVE_DOCUMENT_CATEGORY: "document-category/get?is_active=true",
  ADD_DOCUMENT_CATEGORY: "document-category/add",
  UPDATE_DOCUMENT_CATEGORY: "document-category/edit/",
  GET_ALL_DOCUMENTS_URL: "documents/get",
  ADD_DOCUMENTS_URL: "documents/add",
  UPDATE_DOCUMENTS_URL: "documents/edit/",
  GET_DOCUMENT_PROFILE_URL: "documents/profile/",
  GET_ALL_CUSTOMER_URL: "customer/get",
  GET_ALL_CONSULTATIONS_URL: "customize/get",
  GET_CONSULTATION_PROFILE_URL: "customize/profile/",
  UPLOAD_DOCUMENT_URL: "documents/upload",
  UPDATE_CONSULTATION_STATUS_URL: "customize/consulted-status-change/",
  DELETE_UPLOAD_DOCUMENT_URL: "documents/delete-doc?url=",
  UPDATE_CONVERTED_TO_JOB_STATUS_URL: "customize/status-change/",
  UPDATE_DOCUMENT_STATUS_URL: "documents/status-change/",
  ADD_ZONE_URL: "zones/add",
  GET_ALL_ZONE_URL: "zones/get",
  UPDATE_ZONE_URL: "zones/edit/",
  ASSIGN_DOCUMENT_URL: "customize/assigned-docs/",
  INVOICE_ADD_URL: "invoices/add",
  INVOICE_PROFILE_URL: "invoices/profile/",
  CHANGE_PASSWORD_URL: "admin/change-password",
  UPDATE_SEO_SETTING_URL: "seo/edit",
  GET_SEO_SETTING_URL: "seo/get",
};
